import { Container, Row, Col, Image } from 'react-bootstrap';
import logo from '../images/logo.svg';
import logo80 from '../images/logo_80.png';
import photo1 from '../images/photo1.png';
import photo2 from '../images/photo2.png';
import photo3 from '../images/photo3.png';
import photo4 from '../images/photo4.png';
import videofile from '../video/pobeda_ex_video.mp4'
import plan_desktop from '../images/plan_desktop.png';
import plan_mobile from '../images/plan_mobile.png';
import './MainPage.scss';
import { CustomCarousel } from '../components/CustomCarousel';
import ReactPlayer from 'react-player';

import border2 from '../images/border2.png';
import border3 from '../images/border3.png';


function MainPage() {

  let slides = [
    <div className='essay'>
      <p>
        «Эти незабываемые дни просто врезались в историю и в память людей навечно. И мы будем помнить о стойкости, о героизме людей, которые вопреки всему, выбрали – ВЫСТОЯТЬ И НЕ СКЛОНИТЬСЯ! Выбрали – ЖИТЬ! Для нас, потомков это невероятный пример мужества, отваги и любви. Да, любви! Потому что несмотря ни на что – любовь побеждает! Во-первых, любовь к Отечеству, во-вторых, любовь к ближним, наконец – стремление выжить для будущего своей страны. Для такой большой страны, когда все объединены одним желанием – ПОБЕДИТЬ!»
      </p>
      <div className='cite-bottom'>
        <div className='stamp'></div>
        <b>Карнюшкина Алмина. Таджикистан.</b>
        <br />
        <br />
        Отрывок из сочинения «История не понаслышке».
        <br />
        <br />
        <i>Учитель русского языка и литературы Ходжиева Ибодат Наимовна.</i>
      </div>
    </div>,
    <div className='essay'>
      <p>
        «В Ленинграде холодно, голодно, регулярные обстрелы, гибель ни в чём не повинных людей. А музыка вечна! И она зазвучала в стенах филармонии блокадного города! 9 августа 1942 года зал был переполнен, на глазах зрителей блестели слезы. Симфонию слушали не только жители Ленинграда, но и осадившие город немцы. Они понимали, что никогда не смогут взять осаждённый город, уничтожить жителей этой неприступной крепости, которые в столь трудный час слушают музыку».
      </p>
      <div className='cite-bottom'>
        <div className='stamp'></div>
        <b>Каримова Шахноза. Кыргызстан.</b>
        <br />
        <br />
        Отрывок из сочинения «Память Ленинградской блокады».
        <br />
        <br />
        <i>Учитель русского языка и литературы Цыганова Виктория Михайловна.</i>
      </div>
    </div>,
    <div className='essay'>
      <p>
        «Я мечтаю, чтобы ни один ребенок на свете не испытывал холода, голода и лишений. Но если вдруг придет беда, русский народ  должен помнить, что у него есть верный брат – Узбекистан, который всегда готов прийти на помощь. Мы одна большая семья, у нас общие дети и одно большое сердце на двоих. И тогда и сейчас боль русского народа – наша боль».
      </p>
      <div className='cite-bottom'>
        <div className='stamp'></div>
        <b>Шукуруллаева Бахора. Узбекистан.</b>
        <br />
        <br />
        Отрывок из сочинения «Боль Ленинграда – наша боль».
        <br />
        <br />
        <i>Учитель русского языка Царёва Ольга Львовна.</i>
      </div>
    </div>,
    <div className='essay'>
      <p>
        «Обязательно верь, что совсем скоро советские войска прорвут блокадное кольцо. Кончится голод и мучения. Твой город Ленинград назовут городом-героем, а вы, его жители, станете для многих поколений примером несгибаемой воли и беспримерного подвига».
      </p>
      <div className='cite-bottom'>
        <div className='stamp'></div>
        <b>Кулик Анастасия. Россия.</b>
        <br />
        <br />
        Отрывок из сочинения «Письмо ровеснику в блокадный Ленинград».
        <br />
        <br />
        <i>Учитель русского языка и литературы Ботылева Ирина Викторовна.</i>
      </div>
    </div>,
    <div className='essay'>
      <p>
        «Прапрабабушка Катя всегда носила цветы к памятнику на Пискарёвском кладбище. Так стали делать её дети, потом внуки. Теперь наша очередь. Наша очередь свято хранить память о тех, кто явил нам пример силы духа, твердости, человеческого достоинства, веры и любви. А ещё бережно хранить бесценный дар мирной, счастливой, свободной жизни, чтобы и наши дети помнили и знали о том, что память бесценна».
      </p>
      <div className='cite-bottom'>
        <div className='stamp'></div>
        <b>Орлова Екатерина. Беларусь.</b>
        <br />
        <br />
        Отрывок из сочинения «Ленинград в памяти моей семьи».
        <br />
        <br />
        <i>Учитель русского языка и литературы Михайлова Наталья Владимировна.</i>
      </div>
    </div>
  ]

  return (
    <div className='main-page'>
      <header>
        <div className="main-header">
          <Container className='position-relative'>
            <img src={logo} className="cms-logo" alt="Центр международного сотрудничества" />
            <div className='title-block'>
              <div className='pre-title-block'>
                <img src={logo80} className="logo80" alt="Выставка" />
                <div className='pre-title'>{`к 80-летию Победы в Великой Отечественной войне`}</div>
              </div>
              <div className='title-rect'>
                <h1>ПОБЕДА</h1>
              </div>
              <h2>ОДНА НА ВСЕХ</h2>
            </div>
          </Container>
        </div>
      </header>
      <div className='distance'></div>
      <div className='description-block'>
        <Container>
          <Row className='base-block'>
            <Col>
              <p>
                <b>
                  Центр международного сотрудничества Министерства просвещения Российской Федерации приглашает ознакомиться с выставкой «Победа — одна на всех», посвящённой 79-й годовщине Победы в Великой Отечественной войне.
                </b>
              </p>
              <p>
                Это возможность прикоснуться к важнейшим событиям войны через архивные фотографии, кадры кинохроники, воспоминания фронтовиков, исторические памятники, виртуальные пространства. Участники экспозиций раскроют главные источники Победы, в числе которых — патриотизм, мужество, героизм, несгибаемая воля и единство советского многонационального народа.
              </p>
              <p>
                С помощью материалов выставки классные руководители смогут проводить уроки и классные часы.
              </p>
            </Col>
            <Col md="auto">
              <Image src={photo1} fluid />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='plan-block'>
        <Container>
          <h2 className='text-end'>
            ТЕМАТИЧЕСКИЙ ПЛАН ВЫСТАВКИ
          </h2>
          <Row>
            <Col>
              <Image src={plan_desktop} className='w-100 plan-desktop' />
              <Image src={plan_mobile} className='w-100 plan-mobile' />
              <h3>
                Мы бережем память о прошлом, чтобы защитить наше общее будущее.
              </h3>
              <p>
                Вклад советских народов в победу над фашизмом невозможно переоценить. Миллионы людей из разных регионов СССР сражались на фронтах и в тылу, жертвуя своими жизнями за свободу. Каждая семья бережно хранит память о бессмертном подвиге дедов и прадедов, а имена героев навечно вписаны в летопись общей истории братских народов.
              </p>
              <p>
                <b>
                  Общая память, укрепление и преумножение исторических традиций братства и дружбы — единственный путь к сохранению мира и общечеловеческих ценностей.
                </b>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='video-block'>
        <Container>
          <h2>
            Видеоматериалы
          </h2>
          <h4>Виртуальные туры, кадры кинохроники и военные песни</h4>
          <ReactPlayer
            className="player"
            url={videofile}
            width="100%"
            height="100%"
            controls={true}
            playing={false}
            muted={false}
          />
        </Container>
      </div>
      {/* <div className='contest-block'>
        <div id="background">
          <img src={border2} className="stretch" alt="" />
        </div>
        <Container>
          <h4>Международный конкурс</h4>
          <h2>«Память Ленинградской блокады»</h2>
          <Row>
            <Col md="auto">
              <Image src={photo2} fluid />
            </Col>
            <Col>
              <p>
                <b>
                  На выставке «ПОБЕДА – ОДНА НА ВСЕХ» представлены сочинения победителей Международного конкурса «Память Ленинградской блокады».
                </b>
              </p>
              <p>
                Творческие работы на конкурс представили 2048 школьников из 21 страны мира: России, Белоруссии,  Таджикистана, Германии, Кыргызстана, Египта, Туркменистана, Узбекистана, Южной Осетии, Дании, Анголы, Вьетнама, Казахстана, Монголии, Италии, Молдовы, Болгарии, Кореи, Турции, Эфиопии, Палестины.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='essays-block'>
        <Container>
          <CustomCarousel slides={slides} autoplay={true} interval={5000} />
        </Container>
      </div> */}
      <div className='history-block'>
        <div id="background">
          <img src={border3} className="stretch" alt="" />
        </div>
        <Container>
          <h2 className='text-end'>
            МОЯ ИСТОРИЯ
          </h2>
          <Row>
            <Col>
              <p>
                <b>
                  Великая Отечественная война затронула судьбы миллионов людей.
                </b>
                {' '}Солдаты мужественно защищали Родину на фронте. Женщины, подростки и дети трудились в тылу, чтобы заменить своих мужей, сыновей, отцов и братьев.
              </p>
              <p>
                Практически в каждой семье есть свои воспоминания о том времени. Мы предлагаем извлечь их из семейных архивов. Из этих семейных историй складывается наша общая история Великой Победы.
              </p>
            </Col>
            <Col md="auto">
              <Image src={photo3} fluid />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='form-block'>
        <Container>
          <h2>
            ПОЛУЧИТЬ МАТЕРИАЛЫ ВЫСТАВКИ
          </h2>
          <Row>
            <Col className='md-a'>
              <p>
                Вы можете воспользоваться материалами выставки в цифровом виде или самостоятельно распечатать их в нужном масштабе и таким образом организовать выставку в своей образовательной организации.
              </p>
              <p>
                Чтобы получить материалы выставки, <b>просим вас заполнить форму</b>. Представители Центра международного сотрудничества Минпросвещения России свяжутся с вами для передачи всех необходимых материалов.
              </p>
              <p>
                В визуальных элементах выставки использованы рисунки воспитанников Международной школы «Интердом» им. Е.Д. Стасовой.
              </p>
              <Image src={photo4} fluid className='mt-5' />
            </Col>
            <Col>
              <iframe title='form' src="https://forms.yandex.ru/cloud/6614296243f74f1d2a382f05/?iframe=1" frameBorder="0" name="ya-form-6614296243f74f1d2a382f05" width="650"></iframe>
            </Col>
          </Row>
        </Container>
      </div>
      <footer>
        <Container className='footer-container'>
          <div className='footer-info'>
            <div>
              <a href='https://cic-edu.ru'>
                © «Центр Международного Сотрудничества Министерства Просвещения Российской Федерации», {new Date().getFullYear()} г.
              </a>
            </div>
            <div>
              <i className="bi bi-telephone me-2"></i>
              8 (495) 911‑61‑11
            </div>
            <div>
              <a href='mailto: info@cic-edu.ru'>
                <i className="bi bi-envelope me-2"></i>
                info@cic-edu.ru
              </a>
            </div>
            <div>
              <a href='https://t.me/cms_project'><i className="bi bi-telegram"></i></a>
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
}

export default MainPage;
